import * as React from 'react';
import Helmet from 'react-helmet';
import { graphql } from 'gatsby';
import { groupBy, map, orderBy } from 'lodash';

import { Intro } from 'components/intro/Intro';
import { BlockList } from 'components/block-list/BlockList';
import { IssueItem } from 'components/issue-item/IssueItem';

export const query = graphql`
  query {
    allContentfulHefti (sort: { fields: [volume, issue], order:DESC }) {
      edges {
        node {
          title
          volume
          issue
          published
        }
      }
    }
  }
`;

const mapIssues = ({ node: { published, ...rest } }: any) =>
  ({ published: new Date(published), year: new Date(published).getUTCFullYear(), ...rest });

export default ({ data }: any) =>  {
  const { allContentfulHefti: { edges }} = data;

  const list = edges.map(mapIssues);
  const group = orderBy(map(groupBy(list, (a) => a.year), (value, key) => ({ year: key, issues: value})), 'year', ['desc']);

  return (
    <>
      <Helmet title="Hefti" />

      <Intro>
        Hefti
      </Intro>

      {group.map(({ year, issues }: any) => {
      return (
        <BlockList
          key={year}
          heading={year}
        >
          {orderBy(issues, 'issue', 'desc').map(({ volume, issue, published, title }: any) =>
              <IssueItem
                key={`${volume}-${issue}`}
                to={`/hefti/${volume}/${issue}`}
                heading={`${title} (${volume}. árgangur, ${issue}. hefti, ${published.getUTCFullYear()})`}
              />,
          )}
        </BlockList>
      );
    })}

    </>
  );
};
